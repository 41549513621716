class ShortcutsMenu {
    overallMenuWidth = 0;
    menuItems = null;

    resizeObserver = new ResizeObserver(() => {
        this.applyResponsiveClassNames();
    });

    get menuContainerEl() {
        return document.querySelector('.shortcuts-menu');
    }

    get menuContainerWidth() {
        return this.menuContainerEl ? this.menuContainerEl.offsetWidth : null;
    }

    get menuIsTooWide() {
        return this.overallMenuWidth > this.menuContainerWidth;
    }

    get hasUnifiedShortcutsMenu() {
        const headerEl = document.getElementById('header-v2');

        if (!headerEl) {
            return false;
        }

        const headerClasslist = headerEl.classList;

        return ['variant-shortcuts-unified', 'variant-shortcuts-unified-alt'].some(className => headerClasslist.contains(className));
    }

    assignMenuItems() {
        const shortcutMenu = document.querySelector('.shortcuts-menu');
        const menuItems = Array.from(shortcutMenu.querySelectorAll('li'));

        return menuItems.map((item, index) => {
            let width;

            if (index < menuItems.length) {
                width = item.offsetWidth + 15;
            } else {
                width = item.offsetWidth;
            }

            return { item, width };
        });
    }

    applyResponsiveClassNames() {
        if (this.menuItems) {
            let dynamicMenuWidth = 0;
            let index = 0;

            for (const { width } of this.menuItems) {
                dynamicMenuWidth += width;

                if (dynamicMenuWidth < this.menuContainerWidth) {
                    index++;
                } else {
                    break;
                }
            }

            const menuItemsToShow = this.menuItems.slice(0, index + 1);
            const menuItemsToHide = this.menuItems.slice(index, this.menuItems.length);

            menuItemsToShow.forEach(({ item }) => {
                if (item.classList.contains('hidden')) {
                    item.classList.remove('hidden');
                }
            });

            menuItemsToHide.forEach(({ item }) => {
                item.classList.add('hidden');
            });
        }
    }

    init() {
        if (this.hasUnifiedShortcutsMenu) {
            this.menuItems = this.assignMenuItems();
            this.overallMenuWidth = this.menuItems.reduce((totalWidth, item) => {
                return totalWidth += item.width;
            }, 0);

            this.resizeObserver.observe(document.querySelector('body'));
        }
    }
}

export const shortcutsMenu = new ShortcutsMenu;
