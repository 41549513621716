export default function initGenresList() {
    const genres = document.querySelectorAll('.genres-list li');

    const toggleGenre = (button, input, remove) => {
        if (input.checked) {
            button.classList.add('active');
            input.setAttribute('checked', 'checked');
            remove.classList.add('visible');
        } else {
            button.classList.remove('active');
            input.removeAttribute('checked', 'checked');
            remove.classList.remove('visible');
        }
    };

    const show_more_btn = document.querySelector('.genres-list .show-more-btn');
    if (show_more_btn) {
        show_more_btn.onclick = () => {
            genres.forEach(genre => {
                genre.style.display = null;
            });

            show_more_btn.remove();
        };
    }

    genres.forEach(genre => {
        const genre_btn = genre.querySelector('.genre-btn');
        const genre_input = genre.querySelector('input[type="checkbox"]');
        const genre_remove = genre.querySelector('.remove-genre');

        if (genre_btn) {
            genre_input.addEventListener('change', () => {
                toggleGenre(genre_btn, genre_input, genre_remove);
                genre.closest('form').submit();
            });
        }
    });
}
