import { EmbedMode } from './embed';

/**
 * Base class to generate embed code for a given URL.
 *
 * Derived classes can take any approach (oEmbed, API, etc),
 * as long as they implement the interface defined here.
 *
 * Expects a root element with a placeholder element that we can replace.
 */
export abstract class Embed {
    protected element:HTMLElement;
    protected url:string;
    protected mode:EmbedMode;
    protected player:any;

    constructor(element:HTMLElement, url:string, mode:EmbedMode = 'default') {
        this.element = element;
        this.url = url;
        this.mode = mode;
        this.player;

        if (!element.querySelector('.placeholder')) {
            // eslint-disable-next-line no-console
            console.error('Embeds require a child element with the .placeholder classname!');
        }
    }

    placeholder(): HTMLElement {
        // we need a placeholder element to replace, or insert the embed into
        return this.element.querySelector('.placeholder');
    }

    /**
     * Generate the embed code and replace the placeholder with it.
     */
    abstract load(): void

    /**
     * Expose a way to start the embedded media.
     */
    abstract play(): void

    /**
     * Expose a way to stop the embedded media.
     */
    abstract pause(): void
}
